import { render, staticRenderFns } from "./DHLReport.vue?vue&type=template&id=d671efde&"
import script from "./DHLReport.vue?vue&type=script&lang=js&"
export * from "./DHLReport.vue?vue&type=script&lang=js&"
import style0 from "./DHLReport.vue?vue&type=style&index=0&id=d671efde&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports